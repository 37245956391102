module.exports = {
  F1_1: require('./fencing/fence1-1.jpg'),
  F1_2: require('./fencing/fence1-2.jpg'),
  F1_3: require('./fencing/fence1-3.jpg'),
  F1_4: require('./fencing/fence1-4.jpg'),
  F1_5: require('./fencing/fence1-5.jpg'),

  H1_1: require('./home-prep/home1-1.jpg'),
  H1_2: require('./home-prep/home1-2.jpg'),
  H1_3: require('./home-prep/home1-3.jpg'),
  H1_4: require('./home-prep/home1-4.jpg'),
  H1_5: require('./home-prep/home1-5.jpg'),
  H2_1: require('./home-prep/home2-1.jpg'),
  H2_2: require('./home-prep/home2-2.jpg'),
  H2_3: require('./home-prep/home2-3.jpg'),
  H3_1: require('./home-prep/home3-1.jpg'),
  H3_2: require('./home-prep/home3-2.jpg'),
  H3_3: require('./home-prep/home3-3.jpg'),
  H3_4: require('./home-prep/home3-4.jpg'),
  H3_5: require('./home-prep/home3-5.jpg'),
  H3_6: require('./home-prep/home3-6.jpg'),

  LC1_1: require('./land-clearing/clearing1-1.png'),
  LC1_2: require('./land-clearing/clearing1-2.png'),
  LC1_3: require('./land-clearing/clearing1-3.png'),
  LC1_4: require('./land-clearing/clearing1-4.png'),
  LC2_1: require('./land-clearing/clearing2-1.jpg'),
  LC2_2: require('./land-clearing/clearing2-2.jpg'),
  LC2_3: require('./land-clearing/clearing2-3.jpg'),
  LC3_1: require('./land-clearing/clearing3-1.jpg'),

  RM1_1: require('./road-maintenance/lot1-1.jpg'),
  RM1_2: require('./road-maintenance/lot1-2.jpg'),
  RM1_3: require('./road-maintenance/lot1-3.jpg'),
  RM1_4: require('./road-maintenance/lot1-4.jpg'),
  RM2_1: require('./road-maintenance/lot2-1.jpg'),
  RM2_2: require('./road-maintenance/lot2-2.jpg'),
  RM2_3: require('./road-maintenance/lot2-3.jpg'),

  T1_1: require('./trenching/trenching1-1.jpg'),
  T1_2: require('./trenching/trenching1-2.jpg'),
  T1_3: require('./trenching/trenching1-3.jpg'),
  T1_4: require('./trenching/trenching1-4.jpg'),

  M_1: require('./miscellaneous/misc1.jpg'),
  M_2: require('./miscellaneous/misc2.jpg'),
  SI_1: require('./miscellaneous/sep-install1.jpg'),
  SI_2: require('./miscellaneous/sep-install2.jpg'),
  SI_3: require('./miscellaneous/sep-install3.jpg'),
  SI_4: require('./miscellaneous/sep-install4.jpg'),
  TM_1: require('./miscellaneous/tomill-1.jpg'),
  TM_2: require('./miscellaneous/tomill-2.jpg'),
  TM_3: require('./miscellaneous/tomill-3.jpg')
}